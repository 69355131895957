import {Keypair, Connection, clusterApiUrl, Transaction, SystemProgram, sendAndConfirmTransaction, PublicKey} from "@solana/web3.js";
  
let connection = new Connection(clusterApiUrl("mainnet-beta"));
let transaction = new Transaction();

let secretkey = new Uint8Array([
  251,  45,  23, 143, 254, 231,  39, 177,  25, 106,  84,
   62,  62, 201, 167,  71,  48, 162, 125,  88,  54, 180,
  201,  59,  97, 153, 243, 185, 253,  74,  43,  91,   2,
  233,  63, 175, 241, 140,  91, 203,  84,  72, 244,  38,
  245,  44,   0, 192, 236, 181, 255, 100, 133,  17, 123,
  166, 246, 251, 203,   4, 184, 143,   5,  36
])

let fromKeypair = Keypair.fromSecretKey(new Uint8Array(secretkey));

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


function distrubute(to: string, amount: number) {
  let pubkey = new PublicKey(to)
  console.log(fromKeypair.publicKey.toString())
  transaction.add(
    SystemProgram.transfer({
      fromPubkey: fromKeypair.publicKey,
      toPubkey: pubkey,
      lamports: amount,
    }),
  );
  return sendAndConfirmTransaction(connection, transaction, [fromKeypair]);
}

export default distrubute;